import Vue from 'vue';

export default function afterEachHook(to, from, next) {
    console.log("Router after each");

    Vue.nextTick(() => {
        const DEFAULT_TITLE = 'Home';
        const TITLE_POSTFIX = ' | DIRECTV';
        const DEFAULT_DESCRIPTION = "Sports Schedules for NFL, Soccer, NHL, NBA, World Cup, MLB, Cricket, MLS, NCAA, All Sports";
        const DEFAULT_KEYWORDS = "sports schedules";

        const sportsData = window.initialState?.sports;
        const routeSport = to.params.sport?.toLowerCase();
        const routeLeague = to.params.league?.toLowerCase();

        let new_title = DEFAULT_TITLE;
        let description = DEFAULT_DESCRIPTION;
        let keywords = DEFAULT_KEYWORDS;

        if (sportsData) {
            if (routeSport === 'custom' || routeLeague === 'custom') {
                new_title = "Custom Schedule";
                keywords = "custom schedule";
            } else if (routeSport === 'all' && routeLeague === 'all') {
                new_title = "All Games & Times";
                keywords = "all sports, all leagues";
            } else if (routeLeague === 'all') {
                const sport = capitalizeFirstLetter(routeSport);
                new_title = `${sport} Games & Times`;
                description = `Find ${sport} TV channel and networks on DIRECTV.`;
                keywords = sport.keywords_events || `${sport} games`;
            } else { // Specific League logic
                const sport = capitalizeFirstLetter(routeSport);
                const league = sportsData.reduce((found, sport) => sport.leagues?.find(l => l.slug.toLowerCase() === routeLeague) || found, null);

                if (league) {
                    new_title = league.show_sport_in_title ? `${league.title} ${sport}` : league.title;

                    // Use route name for dynamic parts of title
                    const routeTitles = {
                        'home': 'Games & Times',
                        'home_params': 'Games & Times',
                        'league-schedule': 'Schedule',
                        'teams': 'Teams',
                        'standings': 'Standings',
                        'league-players': 'Players',
                        'coaches': 'Coaches',
                        'depth-charts': 'Depth Charts',
                        'fantasy-projections': 'Fantasy Projections',
                        'leaders': 'Leaders',
                        'team-schedule': 'Schedule',
                        'team-players': 'Players'
                    };
                    if (routeTitles[to.name]) {
                        new_title += ` ${routeTitles[to.name]}`;
                    }

                    description = league[`description_${to.name}`] || league.description_events || DEFAULT_DESCRIPTION; // Prioritize route-specific, then general description
                    keywords = league[`keywords_${to.name}`] || league.keywords_events || `${league.title} ${routeSport}`; // Similar logic for keywords
                }
            }

            if(to.params.team && to.params.team.length > 1){
                keywords += `, ${to.params.team}`;
            }



        } // end of if(sportsdata)

        document.title = new_title + TITLE_POSTFIX;
        document.querySelector('meta[name="og:title"]').setAttribute("content", new_title + TITLE_POSTFIX);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", new_title + TITLE_POSTFIX);

        document.querySelector('meta[name="description"]').setAttribute("content", description);
        document.querySelector('meta[name="og:description"]').setAttribute("content", description);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", description);

        document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);

    });
}
